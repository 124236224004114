
// Write your custom style. Feel free to split your code to several files

@media only screen and (min-width: 2200px) {
    video {
      margin-top: 9vh;;
    }
    .container {
      min-width: 62vw;
    }
    .navbar{
      font-size: 1.5rem;
    }
    .logo-light {
      width: 20vw;
    }
    .logo-dark {
      width: 20vw;
    }
    #faster {
      font-size: 13rem;
    }
    #threemin {
      font-size: 2.7rem;
    }
}

@media only screen and (min-width: 1920px) {
    .display-1 {
        font-size: 7rem;
    }
}

@media only screen and (max-width: 150vh) {
  video {
    margin-top: 10vh;
  }
  #faster {
    margin-top: 0;
  }
}

@media only screen and (min-width: 100vh) {
  #faster {
    margin-top: 5vh;
  }
}

@media only screen and (max-width: 99vh) {
  video {
    margin-top: 10vh;
  }
  #covermain {
    width: 100vw;
  }
}

@media only screen and (max-width: 70vh) {
  video {
    margin-top: 20vh;
  }
  #covermain {
    width: 100vw;
  }
  #faster {
    margin-top: 0;
  }
}

@media only screen and (max-width: 55vh) {
  video {
    margin-top: 40vw;
  }
  #covermain {
    width: 100vw;
  }
}





video {
  width: 58vw;
}

body {
  background-color: #000;
  border-top: 0.3rem solid $blue;
}

a {
  cursor:pointer;
}

.blank {
  opacity: 0;
}

.blankn {
  opacity: 0;
  text-transform: uppercase;
  font-size: 3rem;
  line-height: 5rem;
  text-shadow: 0 0 15px #000;
}

.pl {
  font-size: 1rem;
}

.plz {
  font-size: 2rem;
}

.plx {
  font-size: 3rem;
}

@include media-breakpoint-up(sm) {
  .pl {
    font-size: 1.2rem;
  }
}

@include media-breakpoint-up(md) {
  .pl {
    font-size: 1.4rem;
  }
}

@include media-breakpoint-up(lg) {
  .pl {
    font-size: 1.6rem;
  }
}

.footer {
  padding: 1.25rem 0;
  background-color: #000;
  border: none;
}

.nav-link {
  font-weight: 400;
  text-transform: none;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  color: $navbar-light-color;
}

.nav-link:hover {
  color: #fff;
}

.social {
  color: $navbar-light-color;
}

.btn {
  border-radius: 0.4rem;
  border: none;
  font-family: 'Iceland', cursive;
  font-size: 1.3rem;
  padding: 2px 12px;
}

.btn-lg {
  font-size: 2rem;
  padding: 0 24px;
}

.btn-xl {
  background-color: #fff;
  font-size: 3rem;
  padding: 0 24px;
}

.btn-xl:hover {
  background-color: #000;
  color: $blue;
}

.btn-round {
  border-radius: 0.4rem;
}

.btn-dark {
  background-color: $blue;
  color: #000;
}

.btn-dark2 {
  background-color: $blue;
  color: #fff;
}

.btn-grey {
  background-color: $color-text-dark;
  color: #000;
}

.btn-dark:hover {
  background-color: #fff;
  color: $blue;
}

.btn-dark2:hover {
  background-color: #fff;
  color: $blue;
}

.btn-grey:hover {
  background-color: #fff;
  color: $blue;
}

.btn-light {
  background-color: #fff;
  color: $blue;
}

.btn-light:hover {
  background-color: $blue;
  color: #fff;
}

.btn-xl {
  font-size: 2rem;
}

.scroll-down-1 {

  span {
    animation: scrollDown1 2s 3;
  }
}

.bluebg {
  background-color: $blue;
}

.pricing-2 {
  text-align: center;

  .price {
    font-size: 6rem;
    font-weight: 400;
    opacity: 1;
    color: $blue;
  }

  .plan-name {
    text-transform: uppercase;
    font-weight: 400;
    font-size: 1.5rem;
    opacity: 1;
  }

  .plan-description {
    opacity: 1;
  }

}

.dividerworks {
  background-color: #fff;
  height: 0.1rem;
  width: 60%;
}

#plusplus {
  color: $blue;
}

.tandcs {
  font-size: 0.5375rem;
}

.pricing-3 .popular-tag {
    background-color: $blue;
    font-size: 1rem;
    font-weight: 400;
}

.pricing-3 .plan-name {
    text-transform: uppercase;
    font-weight: 600;
    font-size: 2rem;
}

.pricing-3 .price {
    font-size: 5rem;
    font-weight: 500;
    margin-top: 0;
    margin-bottom: 0;
}

.section-header {
    text-align: center;
    max-width: 70%;
    margin: 0 auto;
}

.input-glass .form-control {
    background-color: rgba(0,0,0,0.8);
    border: 1px solid #333333;
}
